import { useQuery } from '@tanstack/react-query';
import { fetchUserData } from '../api/fetchUserData';

const FETCH_USER_DATA_QUERY_KEY = 'fetch/users-me';

const useUserDataQuery = (tenant: string | null) => {
    return useQuery({ queryKey: [FETCH_USER_DATA_QUERY_KEY, tenant], queryFn: () => fetchUserData(tenant) });
};

export default useUserDataQuery;
