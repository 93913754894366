import { config } from '../../../config/config';
import fetcher from '../../../lib/fetcher';
import { User } from '../types/user.types';

export type UpdateUserModulesOptions = {
    userId: string;
    moduleIds: string[];
    tenant: string | null;
};

export default async function updateUserModules({ userId, moduleIds, tenant }: UpdateUserModulesOptions) {
    if (!tenant) throw Error('update user modules as tenant string is missing');

    const queryString = `?tenantName=${tenant}`;

    return fetcher<User>(`${config.apiUrl}/users/${userId}/modules${queryString}`, {
        method: 'PUT',
        body: { moduleIds },
    });
}
