import { useState } from 'react';
import UserBar from '../../../components/navigation/UserBar';
import UserFormModal from './UserFormModal';
import UserAdminList from './UserAdminList';
import EditUserFormContext from '../contexts/EditUserFormContext';
import { User } from '../types/user.types';
import SyncUsersButton from './SyncUsersButton';
import './UserAdmin.css';

const UserAdmin = () => {
    const [showUserForm, setShowUserForm] = useState(false);
    const [selectedEditUser, setSelectedEditUser] = useState<User | null>(null);

    const handleCloseUserFrom = () => {
        setShowUserForm(false);
        setSelectedEditUser(null);
    };

    return (
        <EditUserFormContext.Provider value={setSelectedEditUser}>
            <UserBar />
            <div className="user-admin">
                <h2>User Admin</h2>
                <h4 className="user-admin-subheading">Manage users, assign permissions and grant platform access</h4>

                <SyncUsersButton />
                <hr className="divider" />

                <UserAdminList onAddUserClick={() => setShowUserForm(true)} />
                <UserFormModal onClose={handleCloseUserFrom} showUserForm={showUserForm || !!selectedEditUser} selectedEditUser={selectedEditUser} />
            </div>
        </EditUserFormContext.Provider>
    );
};

export default UserAdmin;
