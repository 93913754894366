import { createContext } from 'react';

export interface PersistentTimeoutContextType {
    inProgress: boolean;
    startTimeout: (timeInMs: number) => void;
}

const PersistentTimeoutContext = createContext<PersistentTimeoutContextType | null>(null);

export default PersistentTimeoutContext;
