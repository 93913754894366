import { useCallback, useState } from 'react';
import TextInput from '../../../components/ui/TextInput';
import Button from '../../../components/ui/Button';
import ButtonSecondary from '../../../components/ui/ButtonSecondary';
import Widget from './Widget';
import { capitaliseWord } from '../../../utils/string';
import createUser, { CreateUserOptions } from '../api/createUser';
import useTenantContext from '../../dashboard/hooks/useTenantContext';
import useTenantModulesQuery from '../../../hooks/useTenantModulesQuery';
import { useToast } from '../../../hooks/useToast';
import logger from '../../../lib/logger';
import CompactCheckbox from './CompactCheckbox';
import './UserForm.css';
import { User } from '../types/user.types';
import updateUserModules, { UpdateUserModulesOptions } from '../api/updateUserModules';
import { FiUser, FiUserPlus } from 'react-icons/fi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { StandardApiError } from '../../../types/api';
import { FETCH_USERS_QUERY_KEY } from '../hooks/useUsers';

interface UserFormProps {
    onClose: () => void;
    selectedEditUser: null | User;
}

const getModuleIdsFromModules = (modules: User['modules'] = []) => modules.map((module) => module.id);

const UserForm = ({ onClose, selectedEditUser }: UserFormProps) => {
    const [email, setEmail] = useState(selectedEditUser?.email || '');
    const [selectedModulesIds, setSelectedModulesIds] = useState<string[]>(getModuleIdsFromModules(selectedEditUser?.modules));
    const tenant = useTenantContext();
    const { data, isLoading } = useTenantModulesQuery(tenant);
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const createUserMutation = useMutation<User, StandardApiError, CreateUserOptions>({
        mutationKey: ['post/create-user'],
        mutationFn: createUser,
        onSettled: () => queryClient.invalidateQueries({ queryKey: [FETCH_USERS_QUERY_KEY] }),
    });

    const updateUserMutation = useMutation<User, StandardApiError, UpdateUserModulesOptions>({
        mutationKey: ['put/user-modules'],
        mutationFn: updateUserModules,
        onSettled: () => queryClient.invalidateQueries({ queryKey: [FETCH_USERS_QUERY_KEY] }),
    });

    const combinedError = createUserMutation.error || updateUserMutation.error;

    const handleChangeEmail = (value: string) => {
        setEmail(value);
    };

    const handleCheckboxChange = useCallback((moduleId: string) => {
        setSelectedModulesIds((prevState) => {
            if (prevState.includes(moduleId)) return prevState.filter((id) => id !== moduleId);
            return [...prevState, moduleId];
        });
    }, []);

    const handleCreateSubmit = async () => {
        try {
            await createUserMutation.mutateAsync({ email, moduleIds: selectedModulesIds });
            showToast('New User Added');
            onClose();
        } catch (error) {
            // errors presented to user from mutation
            logger.warn(`failed to create user, ${(error as Error)?.message}`);
        }
    };

    const handleEditSubmit = async (userId: string) => {
        try {
            await updateUserMutation.mutateAsync({ userId, moduleIds: selectedModulesIds, tenant });
            showToast('User Information Updated');
            onClose();
        } catch (error) {
            // errors presented to user from mutation
            logger.warn(`failed to update user modules, ${(error as Error)?.message}`);
        }
    };

    return (
        <div className="user-form">
            <div className="user-form-header">
                <div className="user-form-heading">
                    <Widget iconType={selectedEditUser ? FiUser : FiUserPlus} />
                    <h5>{selectedEditUser ? 'Edit User Information' : 'Add New User'}</h5>
                </div>
                <hr className="divider" />
            </div>

            <h6>User Information</h6>
            <div className="user-form-inputs">
                <TextInput
                    label="Email Address"
                    name="email"
                    value={email}
                    type="email"
                    onChange={handleChangeEmail}
                    errorMessage={combinedError?.status === 404 ? combinedError.detail : null}
                    disabled={!!selectedEditUser}
                />

                {selectedEditUser && (
                    <TextInput label="User ID" name="oktaUserId" value={selectedEditUser.id} type="text" onChange={() => {}} disabled />
                )}
            </div>

            <h6>Platform Access</h6>
            <div className="user-form-platform-checkboxes">
                {isLoading ? (
                    <p>Loading platforms, please wait...</p>
                ) : (
                    (data || []).map(({ id, moduleType }) => (
                        <CompactCheckbox
                            key={id}
                            name={moduleType}
                            label={capitaliseWord(moduleType)}
                            checked={selectedModulesIds.includes(id)}
                            onChange={() => handleCheckboxChange(id)}
                        />
                    ))
                )}
            </div>

            {combinedError && combinedError.status !== 404 && <p className="error-message">{combinedError.detail}</p>}

            <div className="user-form-buttons">
                <ButtonSecondary label="Cancel" onClick={onClose} />
                {selectedEditUser ? (
                    <Button label="Update User" onClick={() => void handleEditSubmit(selectedEditUser.id)} disabled={updateUserMutation.isPending} />
                ) : (
                    <Button label="Create User" onClick={() => void handleCreateSubmit()} disabled={createUserMutation.isPending || email === ''} />
                )}
            </div>
        </div>
    );
};

export default UserForm;
