import React from 'react';
import SectionDivider from './SectionDivider';
import Pagination from '../../../components/pagination/Pagination';
import './PaginationSummary.css';

interface PaginationSummaryProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
    totalResults: number;
    currentResults: number;
}

const PaginationSummary: React.FC<PaginationSummaryProps> = ({ currentPage, totalPages, onPageChange, totalResults, currentResults }) => (
    <div className="pagination-section">
        <SectionDivider className="no-margin" />
        <div className="pagination-summary">
            <div className="pagination-details">
                <span className="results-count">{`${currentResults} of ${totalResults} results`}</span>
                <Pagination activePage={currentPage} totalPages={totalPages} onChange={onPageChange} />
            </div>
            <span className="results-count">{`${currentPage} of ${totalPages || 1} pages`}</span>
        </div>
    </div>
);

export default PaginationSummary;
