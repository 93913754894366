import React from 'react';
import './Pagination.css';

interface PaginationProps {
    activePage: number;
    totalPages: number;
    delta?: number;
    onChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ activePage, totalPages, onChange, delta = 2 }) => {
    // Create an array of page numbers within a window around the active page
    // Example: if totalPages=10, activePage=5, delta=2
    // Result: [3,4,5,6,7] (shows 2 pages before and after current page)
    const pageNumbers = Array.from({ length: totalPages }, (_, page) => page + 1).filter(
        (page) => page >= activePage - delta && page <= activePage + delta,
    );

    return (
        <>
            <div className="pagination-container">
                <div className="pagination-buttons">
                    {pageNumbers.map((page) => (
                        <button key={page} className={`pagination-button ${page === activePage ? 'active' : ''}`} onClick={() => onChange(page)}>
                            {page}
                        </button>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Pagination;
