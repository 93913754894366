import { config } from '../../../config/config';
import fetcher from '../../../lib/fetcher';
import { FetchUsersParams, UserApiResponse } from '../types/user.types';

export const fetchUsers = async (params: FetchUsersParams): Promise<UserApiResponse> => {
    if (!params.tenantName) throw Error('failed to fetch users as tenant string missing');

    const queryParams = new URLSearchParams();
    queryParams.append('tenantName', params.tenantName);
    queryParams.append('page', (params.page || 1).toString());

    if (params.searchTerm) {
        queryParams.append('searchTerm', params.searchTerm);
    }

    if (params.moduleIdsString !== '') {
        queryParams.append('moduleIds', params.moduleIdsString);
    }

    if (params.limit) {
        queryParams.append('limit', params.limit.toString());
    }

    const queryString = queryParams.toString();

    return fetcher<UserApiResponse>(`${config.apiUrl}/users?${queryString}`, { method: 'GET' });
};
