interface PrivacyPolicyCookieRowProps {
    cookieSubGroup: string;
    isFirstParty?: boolean;
    cookieNames: string[];
}

const PrivacyPolicyCookieRow = ({ cookieSubGroup, isFirstParty, cookieNames }: PrivacyPolicyCookieRowProps) => {
    return (
        <tr>
            <td className="ot-host-td" data-label="Cookie Subgroup">
                <span className="ot-mobile-border"></span>
                {cookieSubGroup}
            </td>

            <td className="ot-cookies-td" data-label="Cookies">
                <span className="ot-mobile-border"></span>
                <span className="ot-cookies-td-content">
                    {cookieNames.map((name, index) => (
                        <>
                            <a
                                key={name}
                                href={`https://cookiepedia.co.uk/cookies/${name}`}
                                rel="noreferrer"
                                target="_blank"
                                aria-label={`${name} Opens in a new tab`}
                            >
                                {name}
                            </a>
                            {cookieNames.length > 1 && index < cookieNames.length && ', '}
                        </>
                    ))}
                </span>
            </td>
            <td className="ot-cookies-type" data-label="Cookies used">
                <span className="ot-mobile-border"></span>
                <span className="ot-cookies-type-td-content">{`${isFirstParty ? 'First' : 'Third'} Party`}</span>
            </td>
        </tr>
    );
};

export default PrivacyPolicyCookieRow;
