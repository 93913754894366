import { config } from '../../../config/config';
import fetcher from '../../../lib/fetcher';
import { User } from '../types/user.types';

export type CreateUserOptions = {
    email: string;
    moduleIds: string[];
};

export default async function createUser(options: CreateUserOptions) {
    return fetcher<User>(`${config.apiUrl}/users`, {
        method: 'POST',
        body: options,
    });
}
