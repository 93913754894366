import { Module } from '../../../types/module';
import fetcher from '../../../lib/fetcher';
import { config } from '../../../config/config';

export interface UserData {
    email: string;
    modules: Module[];
    oktaUserId: string;
}

export const fetchUserData = async (tenant: string | null): Promise<UserData> => {
    if (!tenant) throw Error('failed to fetch user data as tenant string missing');
    return fetcher<UserData>(`${config.apiUrl}/users/me?tenantName=${tenant}`, {
        method: 'GET',
    });
};
