import { ReactNode, useEffect, useState } from 'react';
import PersistentTimeoutContext from '../../contexts/PersistentTimeoutContext';

interface PersistentTimeoutProviderProps {
    children: ReactNode;
}

/** Timeout that can survive component being derendered */
const PersistentTimeoutProvider = ({ children }: PersistentTimeoutProviderProps) => {
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);

    const startTimeout = (timeInMs: number) => {
        setInProgress(true);
        const id = setTimeout(() => setInProgress(false), timeInMs);
        setTimeoutId(id);
    };

    return <PersistentTimeoutContext.Provider value={{ inProgress, startTimeout }}>{children}</PersistentTimeoutContext.Provider>;
};

export default PersistentTimeoutProvider;
