import React from 'react';
import backgroundImage from '../../assets/privacy-page-bg-image.webp';
import PrivacyPolicyCookieRow from './PrivacyPolicyCookieRow';
import { config } from '../../config/config';
import './PrivacyPolicyPage.css';

const PrivacyPolicyPage: React.FC = () => {
    const { appUrl } = config;
    return (
        <div className="privacy-policy-main">
            <div className="hero" style={{ backgroundImage: `url(${backgroundImage})` }}>
                <div className="hero-content">
                    <div className="hero--text">
                        <h1 className="hero--text__title">Personal Data and Cookies Policy</h1>
                        <div className="hero--text__body">
                            <div>
                                <p>Equans UK Personal Data and Cookies Policy</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="one-column-text custom-container">
                <div>
                    <div className="section">
                        <h3>1. Introduction</h3>
                        <p>
                            Equans Services Limited&nbsp; (&ldquo;Equans Services UK&rdquo;, &ldquo;we&rdquo;, &ldquo;our&rdquo;) owns and operates
                            this website (&ldquo;Website&rdquo;). Hence, Equans Services Limited known as Equans UK processes the personal data of its
                            customers and potential customers (herein after jointly referred to as “customers”) and adheres to the applicable
                            regulations pertaining to personal data protection.
                        </p>

                        <p>
                            Equans Services Limited UK operates its Website and Services as a Data Controller for customers wishing to learn more
                            about our products and Services. It is important to us that you feel confident that your personal information is safe and
                            being looked after. We appreciate the trust you place in us and in return we respect our customers’ privacy and are
                            committed to protecting your personal information. Your data is not used for automated decision-making.
                        </p>

                        <p>
                            This policy sets out our commitment to the protection of your privacy and personal data in relation to the Services we
                            provide.&nbsp; The lawful basis of processing your personal data is contract or commercial legitimate interest where you
                            or your employer has a contract with us; compliance with legal obligation (where relevant) or consent in other instances.
                            Where our lawful basis is consent, by using our website you are consenting to us processing your Personal Data as set out
                            in this Policy
                        </p>
                    </div>

                    <div className="section">
                        <h3>2. Purpose of Data Processing</h3>
                        <p>Our Website is available to facilitate the provision of various services including:</p>
                        <ul>
                            <li>Responding to your queries and requests for assistance;</li>
                            <li>Providing you with the products and services you have requested from us;</li>
                            <li>Providing your company with individualized products and services;</li>
                            <li>Tracking your journey across our website to help us improve our services;</li>
                            <li>Potentially passing your data onto other parts of the Equans Group to respond to queries;</li>
                            <li>Complying with our legal and contractual obligations.</li>
                        </ul>
                        <p>As such it is necessary to hold limited personal data on users of our Services in order to:</p>
                        <ul>
                            <li>Create, operate and maintain your account;</li>
                            <li>Allow you to make full use of our Website features;</li>
                            <li>Provide you and/or your Company with support and respond to your enquiries and requests for assistance;</li>
                            <li>To manage and improve our Services and aid in the detection, prevention, and resolution of any technical issues.</li>
                        </ul>
                    </div>

                    <div className="section">
                        <h3>3. Data Categories</h3>
                        <p>
                            When you use our Website, or get in touch with us, we collect personal data in order to provide you with our Services. We
                            only collect data that is necessary for its intended purposes.
                        </p>
                        <p>There are several categories of personal data which may be collected including:</p>
                        <ul>
                            <li>your name and contact details including your contact number and email address</li>
                            <li>the organisation or business you may represent</li>
                            <li>any additional information you may have provided to us</li>
                            <li>cookies and usage data – for more information see below section 8.</li>
                        </ul>
                        <p>Mandatory information is marked with an asterisk in forms.</p>
                    </div>

                    <div className="section">
                        <h3>4. Recipients of the data</h3>
                        <p>
                            Only authorised recipients receive and have access to personal data, in compliance with current regulations. These will
                            be:
                        </p>
                        <ul>
                            <li>our employees involved in the provision of our Services (who are based in the UK);</li>
                            <li>
                                our legal representatives or other concerned parties in circumstances where we reasonably believe that disclosing your
                                personal information is necessary to help identify, contact or bring legal action against anyone damaging, injuring,
                                or interfering with our rights or property, users or anyone else who could be harmed by such activities;
                            </li>
                            <li>
                                our sub-processors which provide software and support for this processing (for example those listed in the cookies
                                section below) and
                            </li>
                            <li>where we are otherwise authorised or required by law to do so.</li>
                        </ul>
                    </div>

                    <div className="section">
                        <h3>5. Data Retention Time</h3>
                        <p>
                            In order to provide you with our products and services, or to resolve any disputes or complaints, we keep your personal
                            data for one year, after which it is deleted from our systems.
                        </p>
                    </div>

                    <div className="section">
                        <h3>6. Storage and Transfer of Data</h3>
                        <p>
                            The majority of our personal data is stored and processed in the UK. We may however need to process or transfer personal
                            data in the EEA under an adequacy decision for example, in situations where another part of the Equans Group would be
                            better suited to responding to your query.
                        </p>
                        <p>
                            Equans Services UK&nbsp; (operating as Equans) verifies that its sub-processors use appropriate technical and
                            organisational measures necessary to ensure that your personal data is protected.
                        </p>
                    </div>

                    <div className="section">
                        <h3>7. Your rights to your data</h3>
                        <p>
                            You have extensive statutory rights regarding the manner in which your personal data is processed by us. You are entitled
                            to access, query, amend, delete, limit or transfer any personal data concerning you – if you wish to exercise any of these
                            rights, please contact us using the details given below.
                        </p>
                        <p>
                            If you have agreed, we and/or selected third parties may use your personal data to provide you with details about offers,
                            promotions, products or services which we believe may be of interest to you. This may include other members of the Equans
                            Group or UK/EEA based companies offering related services. If you change your mind about this at any time, please let us
                            know by getting in touch with us by emailing&nbsp;
                            <a href="mailto:enquiries.uk@equans.com">enquiries.uk@equans.com</a>.
                        </p>
                        <p>For queries related to personal data please use the following details:</p>
                        <p>Attention: Head of Privacy and Data Protection</p>
                        <p>
                            Email:&nbsp;<a href="mailto:privacy.uk@equans.com">privacy.uk@equans.com</a>&nbsp;&nbsp;
                        </p>
                        <p>
                            Equans UK
                            <br />
                            (Equans Services Ltd)
                            <br />
                            4th Floor
                            <br />
                            6 Bevis Marks
                            <br />
                            London&nbsp;
                            <br />
                            EC3A 7AF
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            If you are not satisfied with the response which you receive, you may contact the Information Commissioner`s Office to
                            make a complaint.
                        </p>
                    </div>

                    <div className="section">
                        <h3>8. Cookies</h3>
                        <p>When visiting the Website, a cookie may be installed on your browser.</p>
                        <p>
                            Cookies are small text files that are stored on your machine primarily to ensure that the Website can manage our users`
                            session correctly (“necessary cookies”). You can also allow us to place “statistical cookies” on your browser. These are
                            cookies which improve your user experience through services such as autocomplete functions or provide Website analytics.
                            We may also request your permission to install marketing cookies.
                        </p>
                        <p>Cookies are kept for a maximum period of 12 months and then deleted.</p>
                        <p>The necessary cookies used within this context are:</p>
                        <div id="ot-sdk-cookie-policy">
                            <div id="ot-sdk-cookie-policy-v2" className="ot-sdk-cookie-policy ot-sdk-container">
                                <h4 id="cookie-policy-title" className="ot-sdk-cookie-policy-title">
                                    Cookie List
                                </h4>
                                <div id="cookie-policy-description">
                                    A cookie is a small piece of data (text file) that a website – when visited by a user – asks your browser to store
                                    on your device in order to remember information about you, such as your language preference or login information.
                                    Those cookies are set by us and called first-party cookies. We also use third-party cookies – which are cookies
                                    from a domain different than the domain of the website you are visiting – for our advertising and marketing
                                    efforts. More specifically, we use cookies and other tracking technologies for the following purposes:
                                </div>

                                <section>
                                    <h5 className="cookie-header">Strictly Necessary Cookies</h5>
                                    <p className="cookie-description">
                                        These cookies are necessary for the website to function and cannot be switched off in our systems. They are
                                        usually only set in response to actions made by you which amount to a request for services, such as setting
                                        your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about
                                        these cookies, but some parts of the site will not then work. These cookies do not store any personally
                                        identifiable information.
                                    </p>

                                    <table className="cookie-table">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="ot-table-header ot-host">
                                                    Cookie Subgroup
                                                </th>

                                                <th scope="col" className="ot-table-header ot-cookies">
                                                    Cookies
                                                </th>
                                                <th scope="col" className="ot-table-header ot-cookies-type">
                                                    Cookies used
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <PrivacyPolicyCookieRow cookieSubGroup={appUrl} isFirstParty cookieNames={['@smartr-email']} />
                                            <PrivacyPolicyCookieRow
                                                cookieSubGroup={appUrl}
                                                cookieNames={[
                                                    'okta-cache-storage',
                                                    'okta-original-uri-storage',
                                                    'okta-shared-transaction-storage',
                                                    'okta-token-storage',
                                                ]}
                                            />
                                        </tbody>
                                    </table>
                                </section>
                                <section>
                                    <h5 className="cookie-header">Performance Cookies</h5>
                                    <p className="cookie-description">
                                        These cookies allow us to count visits and traffic sources so we can measure and improve the performance of
                                        our site. They help us to know which pages are the most and least popular and see how visitors move around the
                                        site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these
                                        cookies we will not know when you have visited our site, and will not be able to monitor its performance.
                                    </p>

                                    <table className="cookie-table">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="ot-table-header ot-host">
                                                    Cookie Subgroup
                                                </th>

                                                <th scope="col" className="ot-table-header ot-cookies">
                                                    Cookies
                                                </th>
                                                <th scope="col" className="ot-table-header ot-cookies-type">
                                                    Cookies used
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <PrivacyPolicyCookieRow
                                                cookieSubGroup={appUrl}
                                                cookieNames={['sentryReplaySession', 'AMP_', '__stripe_mid', '_ga', 'gsID', 'sentry-sd', 'session']}
                                            />
                                        </tbody>
                                    </table>
                                </section>
                                <section>
                                    <h5 className="cookie-header">Functional Cookies</h5>
                                    <p className="cookie-description">
                                        These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us
                                        or by third party providers whose services we have added to our pages. If you do not allow these cookies then
                                        some or all of these services may not function properly.
                                    </p>

                                    <p>None</p>
                                </section>
                                <section>
                                    <h5 className="cookie-header">Targeting Cookies</h5>
                                    <p className="cookie-description">
                                        These cookies may be set through our site by our advertising partners. They may be used by those companies to
                                        build a profile of your interests and show you relevant adverts on other sites. They do not store directly
                                        personal information, but are based on uniquely identifying your browser and internet device. If you do not
                                        allow these cookies, you will experience less targeted advertising.
                                    </p>

                                    <p>None</p>
                                </section>
                            </div>
                        </div>
                        <p>
                            A cookie is a small piece of data (text file) that a website – when visited by a user – asks your browser to store on your
                            device in order to remember information about you, such as your language preference or login information. Those cookies
                            are set by us and called first-party cookies. We also use third-party cookies – which are cookies from a domain different
                            than the domain of the website you are visiting – for our advertising and marketing efforts. More specifically, we use
                            cookies and other tracking technologies for the following purposes:
                        </p>
                    </div>

                    <div className="section">
                        <h3>9. Updates</h3>
                        <p>
                            Equans UK (Equans Services Ltd) may update this Personal Data and Cookies Policy at any time. Consequently, you are
                            invited to regularly consult the Personal Data and Cookies Policy that is currently being applied.
                        </p>
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicyPage;
