import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AppRoutes from './routes';
import PersistentTimeoutProvider from './providers/PersistentTimeoutProvider';

const queryClient = new QueryClient();

function App() {
    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <PersistentTimeoutProvider>
                    <AppRoutes />
                </PersistentTimeoutProvider>
            </QueryClientProvider>
        </BrowserRouter>
    );
}

export default App;
