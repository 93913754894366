import { Link } from 'react-router-dom';
import equansDigitalLogo from '../../assets/equans-digital-logo.webp';
import './LoginFooter.css';

const LoginFooter = () => {
    return (
        <div className="login-footer">
            <div className="login-footer-links">
                <a href="https://www.equans.co.uk/" target="_blank" rel="noreferrer">
                    Equans UK
                </a>
                <a href="https://www.equans.co.uk/equans-digital" target="_blank" rel="noreferrer">
                    Equans Digital
                </a>
                <a href="http://smartr.build" target="_blank" rel="noreferrer">
                    Website
                </a>
                <Link to="/privacy-policy">Privacy Policy</Link>
            </div>

            <img src={equansDigitalLogo} alt="Equans Digital Logo" />
        </div>
    );
};

export default LoginFooter;
