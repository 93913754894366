import { useQuery } from '@tanstack/react-query';
import { fetchUsers } from '../api/users';
import { FetchUsersParams } from '../types/user.types';

interface UseUsersParams extends Omit<FetchUsersParams, 'moduleIdsString'> {
    page: number;
    moduleIds: string[];
}

export const FETCH_USERS_QUERY_KEY = 'fetch/users';

const useUsers = ({ tenantName, page = 1, startId, limit, searchTerm, moduleIds }: UseUsersParams) => {
    const moduleIdsString = moduleIds.join(',');

    return useQuery({
        queryKey: [FETCH_USERS_QUERY_KEY, tenantName, page, startId, limit, searchTerm, moduleIdsString],
        queryFn: () => fetchUsers({ tenantName, page, startId, limit, searchTerm, moduleIdsString }),
    });
};

export default useUsers;
