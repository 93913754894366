import { useState } from 'react';
import { UserApiResponse } from '../types/user.types';
import UserAdminTableRow from './UserAdminTableRow';
import './UserAdminTable.css';
import PaginationSummary from './PaginationSummary';

type UserAdminTableProps = {
    data: UserApiResponse | undefined;
    isLoading: boolean;
    isModulesLoading: boolean;
    error: Error | null;
    modulesError: Error | null;
    onPageChange: (page: number) => void;
    currentPage: number;
};

const headings = ['User ID', 'Email', 'Platform Access', ''];

const UserAdminTable = ({ data, isLoading, isModulesLoading, error, modulesError, onPageChange, currentPage }: UserAdminTableProps) => {
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

    const handleUserOptionsToggle = (userId: string) => {
        setSelectedUserId((currentState) => (currentState === userId ? null : userId));
    };

    if (isLoading || isModulesLoading) {
        return <div className="loading-error-message">Loading users...</div>;
    }
    if (error || modulesError) {
        return <div className="loading-error-message">Error loading users: {error?.message}</div>;
    }

    if (!data?.results || data.results.length === 0) {
        return (
            <div className="centered">
                <h4>No results found</h4>
            </div>
        );
    }

    return (
        <>
            <table className="user-table">
                <thead>
                    <tr>
                        {headings.map((heading) => (
                            <th key={heading}>{heading}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.results.map((user) => (
                        <UserAdminTableRow key={user.id} user={user} onClickUserOptions={handleUserOptionsToggle} selectedUserId={selectedUserId} />
                    ))}
                </tbody>
            </table>

            <PaginationSummary
                currentPage={currentPage}
                totalPages={data.totalPages}
                onPageChange={onPageChange}
                totalResults={data.total}
                currentResults={data.results.length}
            />
        </>
    );
};

export default UserAdminTable;
