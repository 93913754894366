import { createElement } from 'react';
import { IconType } from 'react-icons';
import './FilterButton.css';

interface FilterButtonProps {
    children: string;
    iconType: IconType;
    onClick: () => void;
    colorType: 'primary' | 'secondary';
    disabled?: boolean;
}

const FilterButton = ({ onClick, children, iconType, colorType, disabled }: FilterButtonProps) => {
    return (
        <button onClick={onClick} className={`filter-button ${colorType}`} disabled={disabled}>
            {createElement(iconType, { size: 20, className: 'filter-button-icon' })}
            {children}
        </button>
    );
};

export default FilterButton;
