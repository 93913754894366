import { useContext } from 'react';
import PersistentTimeoutContext, { PersistentTimeoutContextType } from '../contexts/PersistentTimeoutContext';

const usePersistentTimeout = (): PersistentTimeoutContextType => {
    const context = useContext(PersistentTimeoutContext);
    if (!context) throw new Error('usePersistentTimeout must be used within a PersistentTimeoutProvider');
    return context;
};

export default usePersistentTimeout;
