import { useMutation } from '@tanstack/react-query';
import { FiLoader } from 'react-icons/fi';
import FilterButton from './FilterButton';
import usersSync from '../api/usersSync';
import usePersistentTimeout from '../../../hooks/usePersistentTimeout';
import './SyncUsersButton.css';

const FIVE_MINUTES_IN_MS = 5 * 60 * 1000;

const SyncUsersButton = () => {
    const { inProgress, startTimeout } = usePersistentTimeout();

    const usersSyncMutation = useMutation({
        mutationKey: ['post/users-sync'],
        mutationFn: usersSync,
        onSuccess: () => {
            startTimeout(FIVE_MINUTES_IN_MS);
        },
    });

    const isLoading = usersSyncMutation.isPending || inProgress;

    return (
        <div className={`sync-users-button${isLoading ? ' loading' : ''}`}>
            <FilterButton
                onClick={usersSyncMutation.mutate}
                iconType={FiLoader}
                colorType="primary"
                disabled={usersSyncMutation.isPending || inProgress}
            >
                OKTA Sync
            </FilterButton>
        </div>
    );
};

export default SyncUsersButton;
